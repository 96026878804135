import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import Fuse from "fuse.js";
import convertArrayToCSV from "convert-array-to-csv";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
import * as Constants from "@/store/constants";
import { updateDealer } from "@/common/salesAppApi";

const firestoreCollection = getFirestoreCollectionConfig();

export const getAllDealers = async (dbCollection) => {
  const dealer = await getDocs(collection(Firebase.db, `${dbCollection}`));
  return dealer.empty
    ? parseDealerDoc(getDocs(collection(Firebase.db, `${dbCollection}`)))
    : parseDealerDoc(dealer);
};

export const parseDealerDoc = (snapshot) => {
  const dealers = [];
  snapshot.forEach((doc) => {
    dealers.push({
      id: doc.data().mongoId,
      state: doc.data().state,
      data: doc.data()
    });
  });
  return dealers;
};

export const selectableId = (state, id) => {
  return `${state}:${id}`;
};

export const dealerSearchFilterHelper = (search, list) => {
  let filter = list;
  if (search !== "" && search !== null) {
    filter = new Fuse(list, fuseConfigDealer)
      .search(search)
      .map((res) => res.item);
  }
  return filter;
};

export const fuseConfigDealer = {
  keys: [
    {
      name: "data.name",
      weight: 0.9
    },
    {
      name: "data.flag",
      weight: 0.8
    },
    {
      name: "data.type",
      weight: 0.8
    },
    {
      name: "data.custId",
      weight: 2
    }
  ],
  includeScore: true,
  shouldSort: true,
  threshold: 0.15,
  findAllMatches: true
};

export const attributeFilterHelper = (state, list) => {
  let filter = list;
  if (state.filters.length > 0) {
    const stateFilters = state.filters.filter(([field]) => field === "state");
    const repFilters = state.filters.filter(([field]) => field === "rep");
    const flagFilters = state.filters.filter(([field]) => field === "flag");
    const typeFilters = state.filters.filter(([field]) => field === "type");
    const statusFilters = state.filters.filter(([field]) => field === "status");
    const sprinkltFilters = state.filters.filter(
      ([field]) => field === "active_sprout_completed"
    );
    if (stateFilters.length > 0) {
      filter = stateFilters
        .map(([field, value]) => filter.filter((p) => p[field] === value))
        .flat();
    }
    if (repFilters.length > 0) {
      filter = repFilters
        .map(([field, value]) =>
          filter.filter((p) => p.data[field]?.value === value)
        )
        .flat();
    }
    if (flagFilters.length > 0) {
      filter = flagFilters
        .map(([field, value]) =>
          filter.filter((p) =>
            p.data[field].find((data) => data?.value === value)
          )
        )
        .flat();
    }
    if (typeFilters.length > 0) {
      filter = typeFilters
        .map(([field, value]) =>
          filter.filter((p) =>
            p.data[field].find((data) => data?.value === value)
          )
        )
        .flat();
    }
    if (statusFilters.length > 0) {
      filter = statusFilters
        .map(([field, value]) =>
          filter.filter((p) => p.data[field].value === value)
        )
        .flat();
    }
    if (sprinkltFilters.length > 0) {
      filter = sprinkltFilters
        .map(([field, value]) =>
          filter.filter((p) =>
            value ? p.data[field] === value : !p.data[field]
          )
        )
        .flat();
    }
  }
  return filter;
};

export const instantiateDealerData = (state) => {
  if (!state.dealerId) {
    return (state.dealer = null);
  }
  const [location, id] = state.dealerId.split(":");
  state.dealer = Object.assign(
    {},
    state.allDealers.find(
      (p) => String(p.id) === id && String(p.state) === location
    ).data
  );

  return state.dealer;
};

export const formatOriginalCopy = (dealer) => {
  dealer.flag = dealer.flag.map((item) => item.value);
  dealer.status = dealer.status.value;
  dealer.type = dealer.type.map((item) => item.value);
  dealer.rep = dealer.rep.value;

  return dealer;
};

export const saveDealer = async (id, changes) => {
  const ref = doc(Firebase.db, firestoreCollection.dealers, `${id}`);
  await setDoc(ref, changes, { merge: true });
};

export const newDealer = (data) => {
  if (data && data._id) {
    return {
      completed: [],
      data: {
        completed: []
      },
      active_sprout_url: data.active_sprout_url || [],
      best_practice_training_url: data.best_practice_training_url || [],
      brochures_up_to_date_url: data.brochures_up_to_date_url || [],
      clean_up_to_date_pos_url: data.clean_up_to_date_pos_url || [],
      competitive_activity_url: data.competitive_activity_url || [],
      conference_contribution_url: data.conference_contribution_ur || [],
      current_active_pos_url: data.current_active_pos_url || [],
      dealer_mpp_training_url: data.dealer_mpp_training_url || [],
      demo_training_url: data.demo_training_url || [],
      diy_dealer_training_url: data.diy_dealer_training_url || [],
      dolphin_sales_url: data.dolphin_sales_url || [],
      ecomm_embedded_url: data.ecomm_embedded_url || [],
      elite_bulletins_url: data.elite_bulletins_url || [],
      elite_facebook_url: data.elite_facebook_url || [],
      glass_media_training_url: data.glass_media_training_url || [],
      glass_sales_url: data.glass_sales_ur || [],
      incentive_url: data.incentive_url || [],
      lam_opportunities_url: data.lam_opportunities_url || [],
      market_current_promo_url: data.market_current_promo_url || [],
      mineral_sales_url: data.mineral_sales_url || [],
      mini_site_active_url: data.mini_site_active_url || [],
      ms_pro_training_url: data.ms_pro_training_url || [],
      ms_sales_url: data.ms_sales_url || [],
      ozone_sales_url: data.ozone_sales_url || [],
      ozone_swc_training_url: data.ozone_swc_training_url || [],
      swc_sales_url: data.swc_sales_url || [],
      top_line_displayed_url: data.top_line_displayed_url || [],
      customised_digital_ring_fencing_url:
        data.customised_digital_ring_fencing_url || [],
      wt_displays_setup_url: data.wt_displays_setup_url || [],
      point_of_sales_items_url: data.point_of_sales_items_url || [],
      mtau_conference_url: data.mtau_conference_url || [],
      mongoId: data._id || "",
      address: data.address.fullAddress || "",
      contactName: data.contactName || "",
      custId: data.priorityId || "",
      email: data.email.map((e) => e).join(", ") || "",
      flag: data.flag || [],
      logo: data.logo || "",
      minisite: data.social.minisite || "",
      mobile: data.sms || "",
      name: data.name || "",
      phone: data.phone || "",
      postcode: data.address.postalCode || "",
      rep: data.rep || "",
      sfId: data.salesForceId || "",
      state: data.address.state || "",
      status: data.status || "",
      type: data.type || [],
      website: data.social.website[0] || ""
    };
  }
};

export const updatedDealer = (data) => {
  return {
    mongoId: data._id || "",
    address: data.address.fullAddress || "",
    contactName: data.contactName || "",
    custId: data.priorityId || "",
    email: data.email.map((e) => e).join(", ") || "",
    flag: data.flag || [],
    logo: data.logo || "",
    minisite: data.social.minisite || "",
    mobile: data.sms || "",
    name: data.name || "",
    phone: data.phone || "",
    postcode: data.address.postalCode || "",
    rep: data.rep?.$oid ? data.rep?.$oid : data.rep ? data.rep : "",
    sfId: data.salesForceId || "",
    state: data.address.state || "",
    status: data.status || "",
    type: data.type || [],
    website: data.social.website[0] || "",
    city: data.address.city || "",
    line1: data.address.line1 || "",
    line2: data.address.line2 || "",
    placeId: data.social.placeId || "",
    xCoordinates: data.location.coordinates[1],
    yCoordinates: data.location.coordinates[0],
    country: data.address.country
  };
};

export const parseDealersToCsvFormatFull = (dealers) => {
  const headings = [
    "CUSTOMER ID",
    "NAME",
    "CONTACT NAME",
    "ADDRESS",
    "EMAIL",
    "MOBILE",
    "MINISITE",
    "PHONE",
    "REP",
    "STATE",
    "POSTCODE",
    "STATUS",
    "WEBSITE",
    "FLAG",
    "TYPE",
    "SPRINKLR"
  ];
  return [
    headings,
    ...dealers.map((dealer) => [
      dealer.data.custId,
      dealer.data.name,
      dealer.data.contactName,
      dealer.data.address,
      dealer.data.email,
      dealer.data.mobile,
      dealer.data.minisite,
      dealer.data.phone,
      dealer.data.rep.label,
      dealer.state,
      dealer.data.postcode,
      dealer.data.status.label,
      dealer.data.website,
      dealer.data.flag.map((f) => f?.label).join(", "),
      dealer.data.type.map((t) => t?.label).join(", "),
      dealer.data.active_sprout_completed
        ? dealer.data.active_sprout_completed
        : false
    ])
  ];
};

export const parseDealersToCsvFormatSelected = (dealers) => {
  const headings = [
    "CUSTOMER ID",
    "NAME",
    "CONTACT NAME",
    "ADDRESS",
    "EMAIL",
    "MOBILE",
    "MINISITE",
    "PHONE",
    "REP",
    "STATE",
    "POSTCODE",
    "STATUS",
    "WEBSITE",
    "FLAG",
    "TYPE",
    "SPRINKLR"
  ];
  return [
    headings,
    ...dealers.map((dealer) => [
      dealer.custId,
      dealer.name,
      dealer.contactName,
      dealer.address,
      dealer.email,
      dealer.mobile,
      dealer.minisite,
      dealer.phone,
      dealer.rep,
      dealer.state,
      dealer.postcode,
      dealer.status,
      dealer.website,
      dealer.flag.map((f) => f).join(", "),
      dealer.type.map((t) => t).join(", "),
      dealer.active_sprout_completed ? dealer.active_sprout_completed : false
    ])
  ];
};

export const downloadCSV = (data, filename) => {
  const csvString = convertArrayToCSV(data);
  const csvData = new Blob([csvString], { type: "text/csv" });
  var csvUrl = URL.createObjectURL(csvData);
  const filename_with_ext = filename + ".csv";

  const link = document.createElement("a");
  link.setAttribute("href", csvUrl);
  link.setAttribute("download", filename_with_ext);
  link.click();
};

export const statusColours = (value) => {
  switch (value) {
    case "ecomm":
      return "bg-ecomm text-gray-800";
    case "leads":
      return "bg-leads text-white";
    case "service":
      return "bg-service text-white";
    case "builder":
      return "bg-builder text-white";
    case "elite":
      return "bg-elite text-gray-800";
    case "mineralswim":
      return "bg-mineralswim text-gray-800";
    case "trade":
      return "bg-trade text-white";
    case "elitebuilder":
      return "bg-elitebuilder text-gray-800";
    case "roboticrepairs":
      return "bg-roboticrepairs text-white";
    case "watertreatmentrepairs":
      return "bg-watertreatmentrepairs text-white";
    case "ozoneswim":
      return "bg-ozoneswim text-white";
    case "supreme":
      return "bg-supreme text-gray-800";
    case "franchisee":
      return "bg-franchisee text-gray-800";
    case "jims":
      return "bg-jims text-gray-800";
    case "commercial":
      return "bg-commercial text-gray-800";
    default:
      return "bg-default text-white";
  }
};

export const LOG_HELPERS = (tab) => {
  switch (tab) {
    case 0:
      return "Basic Info Tab";
    case 1:
      return "Marketing Tab";
    case 2:
      return "Sales Tab";
    case 3:
      return "Training Tab";
    case 4:
      return "Reports Tab";
    case 5:
      return "Ecomm Sales Tab";
    case 6:
      return "Amazon / Ebay Sales Tab";
  }
};

export const parseDataForFirebase = (data) => {
  if (data && data.mongoId) {
    return {
      completed: [],
      data: {
        completed: []
      },
      active_sprout_url: data.active_sprout_url || [],
      best_practice_training_url: data.best_practice_training_url || [],
      brochures_up_to_date_url: data.brochures_up_to_date_url || [],
      clean_up_to_date_pos_url: data.clean_up_to_date_pos_url || [],
      competitive_activity_url: data.competitive_activity_url || [],
      conference_contribution_url: data.conference_contribution_ur || [],
      current_active_pos_url: data.current_active_pos_url || [],
      dealer_mpp_training_url: data.dealer_mpp_training_url || [],
      demo_training_url: data.demo_training_url || [],
      diy_dealer_training_url: data.diy_dealer_training_url || [],
      dolphin_sales_url: data.dolphin_sales_url || [],
      ecomm_embedded_url: data.ecomm_embedded_url || [],
      elite_bulletins_url: data.elite_bulletins_url || [],
      elite_facebook_url: data.elite_facebook_url || [],
      glass_media_training_url: data.glass_media_training_url || [],
      glass_sales_url: data.glass_sales_ur || [],
      incentive_url: data.incentive_url || [],
      lam_opportunities_url: data.lam_opportunities_url || [],
      market_current_promo_url: data.market_current_promo_url || [],
      mineral_sales_url: data.mineral_sales_url || [],
      mini_site_active_url: data.mini_site_active_url || [],
      ms_pro_training_url: data.ms_pro_training_url || [],
      ms_sales_url: data.ms_sales_url || [],
      ozone_sales_url: data.ozone_sales_url || [],
      ozone_swc_training_url: data.ozone_swc_training_url || [],
      swc_sales_url: data.swc_sales_url || [],
      top_line_displayed_url: data.top_line_displayed_url || [],
      customised_digital_ring_fencing_url:
        data.customised_digital_ring_fencing_url || [],
      wt_displays_setup_url: data.wt_displays_setup_url || [],
      point_of_sales_items_url: data.point_of_sales_items_url || [],
      mtau_conference_url: data.mtau_conference_url || [],
      mongoId: data.mongoId || "",
      address: data.address || "",
      contactName: data.contactName || "",
      custId: data.priorityId || "",
      originalCustId: data.originalCustId || "",
      email:
        data.email
          .split(",")
          .map((i) => i.trim())
          .join(", ") || "",
      flag: data.flag.map((i) => i.value) || [],
      logo: data.logo || "",
      minisite: data.minisite || "",
      mobile: data.mobile || "",
      name: data.name || "",
      phone: data.phone || "",
      postcode: data.postCode || "",
      rep: data.rep || "",
      sfId: data.sfId || "",
      state: data.state || "",
      status: data.status || "",
      type: data.type.map((i) => i.value) || [],
      website: data.website || "",
      country: data.country || "",
      fbId: data.fbId || "",
      igId: data.igId || "",
      line1: data.line1 || "",
      line2: data.line2 || "",
      placeId: data.placeId || "",
      xCoordinates: data.xCoordinates,
      yCoordinates: data.yCoordinates,
      city: data.city || "",
      requested_by: data.requested_by || "",
      isRevisedCoordinate: data.isRevisedCoordinate || false
    };
  }
};

export const mapDealerDefaultAttributes = (data, targetType) => {
  return Constants.DEFAULT_ATTRIBUTES.filter(
    (i) => i.field === targetType
  )[0].options.find((x) => x.value === data);
};

export const updateDealerFromMongo = (dealerId, data) => {
  return updateDealer(dealerId, {
    name: data.name,
    salesForceId: data.sfId,
    priorityId: data.custId,
    contactName: data.contactName,
    email: data.email.split(",").map((i) => i.trim()),
    sms: data.mobile,
    phone: data.phone,
    status: data.status,
    rep: data.rep,
    address: {
      fullAddress: data.address,
      line1: data.line1,
      line2: data.line2,
      city: data.city,
      state: data.state,
      postalCode: data.postcode,
      country: data.country
    },
    location: {
      type: "Point",
      coordinates: [data.yCoordinates, data.xCoordinates]
    },
    "social.placeId": data.placeId,
    "social.website": [data.website],
    "social.minisite": data.minisite,
    "social.fbId": data.fbId,
    "social.igId": data.igId,
    logo: data.logo,
    flag: data.flag,
    type: data.type
  });
};

export const getYearOptions = () => {
  let options = [];
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  for (let i = currentYear; i !== 2018; i--) {
    options.push({ label: i.toString(), value: i.toString() });
  }
  return options;
};

export const initialiseSprinklr = (state) => {
  if (state.dealer.completed.includes("active_sprout")) {
    state.dealer.active_sprout_completed = true;
  } else {
    state.dealer.active_sprout_completed = false;
  }
};
