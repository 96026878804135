// auth
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const RESET_STATE = "resetModuleState";
export const SET_LOADING = "setLoading";
export const SET_USERS = "setUsers";
export const INSERT_USER = "insertUser";

// Dealers
export const SET_ALL_DEALERS = "setAllDealers";
export const SET_DEALER_ERROR = "setDealerError";
export const SET_DEALER_LOADING = "setDealerLoading";
export const SET_DEALER_SELECTED = "setDealerSelected";
export const ADD_DEALER_FILTER = "addDealerFilter";
export const REMOVE_DEALER_FILTER = "removeDealerFilter";
export const SET_DEALER_ORDER = "setDealerOrder";
export const SET_DEALERS_SEARCH = "setDealerSearch";
export const REMOVE_ALL_DEALER_FILTER = "removeAlldealerFilter";
export const SET_UPDATED_DEALER = "setUpdatedDealer";
export const SET_DEALER_AMAZON_EBAY_FILTER = "setDealerAmazonEbayFilter";
export const SET_DEALER_REMOVE_AMAZON_EBAY_FILTER =
  "setDealerRemoveAmazonEbayFilter";
export const SET_CREATED_DEALER = "setCreatedDealer";
// Reports
export const SET_REPORT = "setReport";
export const SET_ALL_REPORTS = "setAllReports";
export const SET_REPORT_ERROR = "setReportError";
export const SET_REPORT_LOADING = "setReportLoading";

// Sales
export const SET_SALES = "setSales";

// Order
export const SET_ORDER_ERROR = "setOrderError";
export const SET_ALL_ORDERS = "setAllOrders";
export const SET_ORDER_LOADING = "setOrderLoading";
export const SET_ORDER = "setOrder";
export const SET_UPDATE_ORDER = "setUpdateOrder";
export const ADD_ORDER_FILTER = "addOrderFilter";
export const REMOVE_ALL_ORDER_FILTER = "removeAllOrderFilter";
export const SET_ORDERS_SEARCH = "setOrdersSearch";
export const REMOVE_ORDER_FILTER = "removeOrderFilter";
export const SET_ORDER_SELECTED = "setOrderSelected";
export const SET_ORDER_COUNT = "setOrderCount";
export const SET_ORDER_FROM_MONGO = "setOrderFromMongo";
export const SET_ALL_ORDERS_FROM_MONGO = "setAllOrdersFromMongo";
export const UPDATE_ORDER_FROM_LIST = "updateOrderFromList";
export const SET_ORDER_LOADING_MESSAGE = "setOrderLoadingMessage";

// Charts
export const SET_AMAZON_EBAY_FILTER = "setAmazonEbayFilter";
export const SET_REMOVE_AMAZON_EBAY_FILTER = "setRemoveAmazonEbayFilter";
export const SET_AMAZON_EBAY_LOADING = "setAmazonEbayLoading";

// Commercial
export const SET_CUSTOMER = "setCustomer";
export const SET_ALL_CUSTOMERS = "setAllCustomers";
export const SET_CUSTOMER_LOADING = "setCustomerLoading";
export const SET_CUSTOMER_ERROR = "setCustomerError";
export const SET_CUSTOMERS_SEARCH = "setCustomersSearch";
export const SET_CUSTOMER_SELECTED = "setCustomerSelected";
export const SET_UPDATED_CUSTOMER = "setUpdatedCustomer";
export const REMOVE_DELETED_CUSTOMER = "removeDeletedCustomer";
export const SET_SORT_ORDER = "setSortOrder";
export const SET_CUSTOMER_LOADING_MESSAGE = "setCustomerLoadingMessage";
export const SET_UPDATED_CUSTOMER_TIMESTAMP = "setUpdatedCustomerTimestamp";

// Logs
export const SET_LOG_LOADING = "setLogLoading";
export const SET_LOGS = "setLogs";
export const SET_LOG_ERROR = "setLogError";

// Ably
export const ENTER_ROOM = "enterRoom";
export const LEAVE_ROOM = "leaveRoom";
export const LOAD_ALL_MEMBERS = "loadAllMembers";

// Payments
export const SET_PAYMENTS = "setPayments";
export const SET_PAYMENT_LOADING = "setPaymentLoading";

// Products
export const SET_PRODUCTS = "setProducts";
export const SET_PRODUCT_LOADING = "setProductLoading";
export const SET_CART = "setCart";
export const SET_CART_ITEMS = "setCartItems";

// POS
export const RESET_CART = "resetCart";
export const SET_CART_CUSTOMER = "setCartCustomer";
export const SET_POS_ERROR = "setPosError";
export const SET_ALL_COUPONS = "setAllCoupons";
export const SET_COUPON = "setCoupon";

// Revenue
export const SET_ALL_REVENUE = "setAllRevenue";
export const SET_ALL_SPAREPARTS_REVENUE = "setAllSparepartsRevenue";

// Visitor Log
export const SET_ALL_BRANCHES = "setAllBranches";
export const UPDATE_PERSONNEL = "updatePersonnel";
export const ADD_VISITOR = "addVisitor";
export const SET_ALL_VISITORS = "setAllVisitors";
export const UPDATE_VISITOR = "updateVisitor";
export const SET_VISITOR = "setVisitor";
export const UPDATE_ALL_VISITOR = "updateAllVisitor";
export const PUSH_VISITOR_FILTER = "pushVisitorFilter";
export const DELETE_VISITOR_FILTER = "deleteVisitorFilter";
export const RESET_VISITOR_FILTERS = "resetVisitorFilters";
export const SET_SEARCH_VISITORS = "setSearchVisitors";
export const SET_VISITOR_ERROR = "setVisitorError";
export const SET_SORT_VISITORS = "setSortVisitors";
export const SET_VISITOR_TO_DELETE = "setVisitorToDelete";
export const SET_VISITOR_LOADING = "setVisitorLoading";
export const SET_VISITOR_TODAY = "setVisitorToday";

// Service Request
export const SET_SELECTED_DEALER = "setSelectedDealer";
export const SET_SERVICE_LOADING = "setServiceLoading";
export const SET_SERVICEREQUEST_ERROR = "setServiceRequestError";
export const SET_ALL_SERVICEREQUESTS_PICKUP = "setAllServiceRequestsPickUp";
export const SET_ALL_SERVICEREQUESTS_COUNT = "setAllServiceRequestsCount";
export const SET_ALL_SERVICEREQUESTS_COUNT_DROPOFF =
  "setAllServiceRequestsCountDropOff";
export const SET_ALL_SERVICEREQUESTS_DROPOFF = "setAllServiceRequestsDropOff";
export const SET_PICKUP_SELECTED = "setPickUpSelected";
export const SET_DROPOFF_SELECTED = "setDropOffSelected";

// Register
export const SET_INITIAL_VALIDATION = "initialValidate";
export const SET_REGISTRATION_ERROR = "setRegistrationError";
export const SET_ALL_REGISTRATIONS = "setAllRegistrations";
export const SET_REGISTRATION_LOADING = "setRegistrationLoading";
export const SET_REGISTRATION_TO_DELETE = "setRegistrationToDelete";
export const SET_REGISTRATION_TO_EDIT = "setRegistrationToEdit";
export const SET_REGISTRATION_TO_REGISTER = "setRegistrationToRegister";
export const SET_DEALERUSER_TO_DELETE = "setDealerUserToDelete";
export const SET_VALID_REGISTRATION_COUNT = "setValidRegistrationCount";

// Notification
export const UPDATE_NOTIFICATIONS = "updateNotifications";
export const REMOVE_NOTIFICATION = "removeNotification";
export const SET_NOTIFICATION_ERROR = "setNotificationError";
export const IS_NEW_NOTIFICATION = "isNewNotification";

export const SET_THEME = "setTheme";
