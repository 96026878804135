import * as Constants from "@/store/constants";
export const months = (config) => {
  const cfg = config || {};
  const count = cfg.count || 12;
  const section = cfg.section;
  const values = [];
  let i, value;

  for (i = 0; i < count; ++i) {
    value = Constants.MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
};

export const getChartData = (orders, year) => {
  let ebayFigures = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let amazonFigures = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let koganFigures = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let harveyNormanFigures = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let catchFigures = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let robotLabel = [];
  let robotNumber = [];
  let robotFrequency = {};
  const amazonOrders = orders.filter((i) => i.store === "amazon");
  const ebayOrders = orders.filter((i) => i.store === "ebay");
  const koganOrders = orders.filter((i) => i.store === "kogan");
  const harveyNormanOrders = orders.filter((i) => i.store === "harvey norman");
  const catchOrders = orders.filter((i) => i.store === "catch");
  const currentAmazonOrder = amazonOrders.filter((i) => i.data.year === year);
  const amazonTotalSales = currentAmazonOrder.length;

  if (currentAmazonOrder.length > 0) {
    currentAmazonOrder.map((i) => {
      amazonFigures[Number(i.data.date.split("-")[1]) - 1] =
        Math.round(amazonFigures[Number(i.data.date.split("-")[1]) - 1]) +
        Math.round(Number(i.data.total));
    });
  }

  const currentEbayOrder = ebayOrders.filter((i) => i.data.year === year);
  const ebayTotalSales = currentEbayOrder.length;

  if (currentEbayOrder.length > 0) {
    currentEbayOrder.map((i) => {
      ebayFigures[Number(i.data.date.split("-")[1]) - 1] =
        Math.round(ebayFigures[Number(i.data.date.split("-")[1]) - 1]) +
        Math.round(Number(i.data.total));
    });
  }

  const currentKoganOrder = koganOrders.filter((i) => i.data.year === year);
  const koganTotalSales = currentKoganOrder.length;

  if (currentKoganOrder.length > 0) {
    currentKoganOrder.map((i) => {
      koganFigures[Number(i.data.date.split("-")[1]) - 1] =
        Math.round(koganFigures[Number(i.data.date.split("-")[1]) - 1]) +
        Math.round(Number(i.data.total));
    });
  }
  const currentHarveyNormanOrder = harveyNormanOrders.filter(
    (i) => i.data.year === year
  );
  const harveyNormanTotalSales = currentHarveyNormanOrder.length;

  if (currentHarveyNormanOrder.length > 0) {
    currentHarveyNormanOrder.map((i) => {
      harveyNormanFigures[Number(i.data.date.split("-")[1]) - 1] =
        Math.round(harveyNormanFigures[Number(i.data.date.split("-")[1]) - 1]) +
        Math.round(Number(i.data.total));
    });
  }

  const currentCatchOrder = catchOrders.filter((i) => i.data.year === year);
  const catchTotalSales = currentCatchOrder.length;

  if (currentCatchOrder.length > 0) {
    currentCatchOrder.map((i) => {
      catchFigures[Number(i.data.date.split("-")[1]) - 1] =
        Math.round(catchFigures[Number(i.data.date.split("-")[1]) - 1]) +
        Math.round(Number(i.data.total));
    });
  }

  const getAllProducts = currentAmazonOrder
    .concat(currentEbayOrder)
    .concat(currentKoganOrder)
    .concat(currentHarveyNormanOrder)
    .concat(currentCatchOrder)
    .map((i) => i.data.products)
    .flat();

  getAllProducts.map((x) => {
    if (robotFrequency[x.name]) {
      robotFrequency[x.name] += 1;
    } else {
      robotFrequency[x.name] = 1;
    }
  });
  Object.entries(robotFrequency).map((i) => {
    robotLabel.push(i[0]);
    robotNumber.push(i[1]);
  });

  const amazonEstimatedSales = amazonFigures.reduce((a, b) => a + b, 0);
  const ebayEstimatedSales = ebayFigures.reduce((a, b) => a + b, 0);
  const koganEstimatedSales = koganFigures.reduce((a, b) => a + b, 0);
  const harveyNormanEstimatedSales = harveyNormanFigures.reduce(
    (a, b) => a + b,
    0
  );
  const catchEstimatedSales = catchFigures.reduce((a, b) => a + b, 0);
  return {
    ebayFigures,
    amazonFigures,
    koganFigures,
    harveyNormanFigures,
    catchFigures,
    amazonTotalSales,
    ebayTotalSales,
    koganTotalSales,
    harveyNormanTotalSales,
    catchTotalSales,
    amazonEstimatedSales,
    ebayEstimatedSales,
    koganEstimatedSales,
    harveyNormanEstimatedSales,
    catchEstimatedSales,
    robotLabel,
    robotNumber
  };
};

export const RANDOMISE_COLOR = (times) => {
  let colorArray = [];
  for (let i = 0; i < times; i++) {
    const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
    const randomRGB = `rgba(${randomNum()}, ${randomNum()}, ${randomNum()}, .6)`;
    colorArray.push(randomRGB);
  }
  return colorArray;
};

export const getDealersChartData = (dealers) => {
  let arr = [];
  let dealerType = {};
  let typeLabel = [];
  let typeNumber = [];
  dealers.map((d) => {
    const {
      data: { type }
    } = d;
    if (type.length > 0) {
      arr.push(
        type
          .filter((item) => item !== undefined)
          .map((itemType) => itemType.value)
      );
    }
  });
  arr
    .flat()
    .filter(Boolean)
    .map((x) => {
      if (dealerType[x]) {
        dealerType[x] += 1;
      } else {
        dealerType[x] = 1;
      }
    });
  Object.entries(dealerType).map((i) => {
    typeLabel.push(i[0]);
    typeNumber.push(i[1]);
  });
  return {
    typeLabel,
    typeNumber
  };
};

export const getCurrentYear = () => {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  return currentYear;
};
