import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import * as Helpers from "@/store/helpers";
import * as Firebase from "@/common/firebase.js";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
import { mapDealerDefaultAttributes } from "../helpers/dealers.helpers";
import { getAuth } from "firebase/auth";
import MODULE from "@/store/constants/module.constants";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import { objectCompare } from "../../utils/objectComparator";
import { loadAvatar } from "@/utils/avatarLoader";
import { updateDealer } from "@/common/salesAppApi";

const firestoreCollection = getFirestoreCollectionConfig();

export default {
  async [Actions.GET_ALL_DEALERS]({ commit, rootState, dispatch }) {
    try {
      let userState = rootState.auth.users;
      if (userState.length === 0) {
        await dispatch(Actions.GET_ALL_USERS);
        userState = rootState.auth.users;
      }
      const dealers = await Helpers.getAllDealers(firestoreCollection.dealers);
      let dealerModified = dealers.map((dealer) => {
        dealer.data.type = dealer.data.type.map((type) =>
          mapDealerDefaultAttributes(type, "type")
        );
        dealer.data.flag = dealer.data.flag.map((flag) =>
          mapDealerDefaultAttributes(flag, "flag")
        );
        dealer.data.rep = mapDealerDefaultAttributes(
          dealer.data.rep,
          "rep"
        ) ?? { value: null };
        dealer.data.status = mapDealerDefaultAttributes(
          dealer.data.status,
          "status"
        );

        return dealer;
      });
      dealerModified = loadAvatar(
        userState,
        dealerModified,
        ["mongoId"],
        ["data", "rep", "value"]
      );
      dealerModified = dealerModified.map((dealer) => ({
        ...dealer,
        data: { ...dealer.data, avatar: dealer.avatar }
      }));
      commit(Mutations.SET_ALL_DEALERS, [...dealerModified]);
      commit(Mutations.SET_DEALER_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_DEALER_LOADING, false);
      commit(Mutations.SET_DEALER_ERROR, error);
    }
  },
  [Actions.DEALER_SAVE_SELECTED]({ commit }, selected) {
    commit(Mutations.SET_DEALER_SELECTED, selected);
  },
  [Actions.DEALER_ADD_FILTER]({ commit }, filter) {
    commit(Mutations.ADD_DEALER_FILTER, filter);
  },
  [Actions.DEALER_REMOVE_FILTER]({ commit }, filter) {
    commit(Mutations.REMOVE_DEALER_FILTER, filter);
  },
  [Actions.DEALER_REMOVE_ALL_FILTER]({ commit }) {
    commit(Mutations.REMOVE_ALL_DEALER_FILTER);
  },
  [Actions.ORDER_DEALERS]({ commit }, order) {
    commit(Mutations.SET_DEALER_ORDER, order);
  },
  [Actions.SEARCH_DEALERS]({ commit }, search) {
    commit(Mutations.SET_DEALERS_SEARCH, search);
  },
  [Actions.CREATE_DEALER]: async ({ commit, dispatch }, data) => {
    try {
      const currentUser = getAuth().currentUser;
      const ref = doc(
        Firebase.db,
        firestoreCollection.dealers,
        `${data.mongoId}`
      );
      await setDoc(ref, data, { merge: true });
      await dispatch(Actions.LOG_EVENT, {
        user: currentUser.email,
        action: LOG_ACTIONS.CREATE_DEALER,
        module: MODULE.DEALER,
        target: JSON.stringify({ id: data.mongoId, name: data.name }),
        datePerformed: serverTimestamp()
      });
      commit(Mutations.SET_CREATED_DEALER, data);
    } catch (error) {
      commit(Mutations.SET_DEALER_ERROR, error.message);
    }
  },
  [Actions.UPDATE_DEALER]: async (
    { commit, dispatch },
    { region, id, changes, original }
  ) => {
    const currentUser = getAuth().currentUser;
    await Helpers.saveDealer(id, changes);
    await dispatch(Actions.LOG_EVENT, {
      user: currentUser.email,
      action: LOG_ACTIONS.UPDATE_DEALER,
      module: MODULE.DEALER,
      target: JSON.stringify({ id, name: changes.name }),
      changes: JSON.stringify(objectCompare(original, changes)),
      datePerformed: serverTimestamp()
    });
    changes.type = changes.type.map((type) =>
      mapDealerDefaultAttributes(type, "type")
    );
    changes.flag = changes.flag.map((flag) =>
      mapDealerDefaultAttributes(flag, "flag")
    );
    changes.rep = mapDealerDefaultAttributes(changes.rep, "rep");
    changes.status = mapDealerDefaultAttributes(changes.status, "status");

    commit(Mutations.SET_UPDATED_DEALER, {
      state: region,
      data: changes,
      id: id
    });
  },
  [Actions.GET_SALES]: async ({ commit }, id) => {
    commit(Mutations.SET_SALES, await Helpers.getOrderById(id));
  },
  [Actions.RESET_SALES]: ({ commit }) => {
    commit(Mutations.SET_SALES, []);
  },
  [Actions.FILTER_SALES]: async ({ commit }, { year, id }) => {
    commit(Mutations.SET_SALES, await Helpers.filterSales(year, id));
  },
  [Actions.DEALER_AMAZON_EBAY_CHART_FILTER]({ commit }, filter) {
    commit(Mutations.SET_DEALER_AMAZON_EBAY_FILTER, filter);
  },
  [Actions.DEALER_AMAZON_EBAY_CHART_REMOVE_FILTER]({ commit }, filter) {
    commit(Mutations.SET_DEALER_REMOVE_AMAZON_EBAY_FILTER, filter);
  },
  [Actions.CREATE_LOG]: async ({ commit }, { id, data }) => {
    const ref = doc(Firebase.db, "logs", `${id}`);
    await setDoc(ref, data, { merge: true }).catch((error) => {
      commit(Mutations.SET_REPORT_ERROR, error.message);
    });
  },
  [Actions.UPDATE_DEALER_IS_BUSY]: async (
    { dispatch },
    { id, branch, changes }
  ) => {
    const currentUser = getAuth().currentUser;
    const fields = {
      "branch.is_busy": changes.is_busy
    };
    await updateDealer(id, fields);
    await Helpers.saveDealer(id, changes);
    await dispatch(Actions.LOG_EVENT, {
      user: currentUser.email,
      action: LOG_ACTIONS.UPDATE_DEALER_IS_BUSY,
      module: MODULE.DEALER,
      target: JSON.stringify({
        id,
        name: branch.name,
        is_busy: changes.is_busy
      }),
      datePerformed: serverTimestamp()
    });
  }
};
