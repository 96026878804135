import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  updatePassword,
  confirmPasswordReset,
  EmailAuthProvider,
  reauthenticateWithCredential
} from "firebase/auth";
import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import { auth } from "@/common/firebase";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "../../common/firebase";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();
import {
  createUser,
  getAllUsers,
  sendEmailVerificationLink
} from "../../common/salesAppApi";
import LOG_ACTIONS from "../constants/log_actions.constants";
import MODULE from "../constants/module.constants";

export default {
  async [Actions.LOGIN]({ commit, dispatch }, credentials) {
    try {
      commit(Mutations.SET_LOADING, true);
      await setPersistence(getAuth(), browserSessionPersistence);
      const { user } = await signInWithEmailAndPassword(
        getAuth(),
        credentials.email,
        credentials.password
      );

      const userDocRef = doc(db, firestoreCollection.users, user.email);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const fUser = docSnap.data();
        await setDoc(
          userDocRef,
          { lastSignInTime: user.metadata.lastSignInTime },
          { merge: true }
        );
        commit(Mutations.SET_AUTH, fUser);
        await dispatch(Actions.LOG_EVENT, {
          user: user.email,
          action: LOG_ACTIONS.LOGIN,
          module: MODULE.AUTH,
          target: JSON.stringify({ id: user.uid, name: user.email }),
          datePerformed: serverTimestamp()
        });
      } else {
        commit(Mutations.SET_ERROR, "User not found on firestore");
      }
      commit(Mutations.SET_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_LOADING, false);
      commit(Mutations.SET_ERROR, error.code.split("/")[1]);
    }
  },
  async [Actions.LOGOUT]({ commit, dispatch }) {
    const currentUser = getAuth().currentUser;
    await dispatch(Actions.LOG_EVENT, {
      user: currentUser.email,
      action: LOG_ACTIONS.LOGOUT,
      module: MODULE.AUTH,
      target: JSON.stringify({ id: currentUser.uid, name: currentUser.email }),
      datePerformed: serverTimestamp()
    });
    try {
      await signOut(getAuth());
      commit(Mutations.PURGE_AUTH);
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
    }
  },
  async [Actions.REGISTER]({ commit, dispatch }, payload) {
    try {
      commit(Mutations.SET_LOADING, true);

      const { data } = await createUser(payload);
      const userPayload = {
        ...payload,
        uid: data.uid,
        isFirstLogin: true
      };

      commit(Mutations.INSERT_USER, userPayload);
      await dispatch(Actions.LOG_EVENT, {
        user: getAuth().currentUser.email,
        action: LOG_ACTIONS.CREATE_USER,
        module: MODULE.ADMIN,
        target: JSON.stringify({ name: payload.email, id: data.uid }),
        datePerformed: serverTimestamp()
      });
      commit(Mutations.SET_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_LOADING, false);
      throw error;
    }
  },
  async [Actions.GET_CURRENT_USER]({ commit }) {
    commit(Mutations.SET_LOADING, true);
    const user = getAuth().currentUser;
    if (user) {
      const docSnap = await getDoc(
        doc(db, firestoreCollection.users, user.email)
      );
      if (docSnap.exists()) {
        const fUser = docSnap.data();
        commit(Mutations.SET_AUTH, fUser);
      } else {
        commit(Mutations.SET_ERROR, "User not found on firestore");
      }
    }
    commit(Mutations.SET_LOADING, false);
  },
  async [Actions.GET_ALL_USERS]({ commit }) {
    try {
      commit(Mutations.SET_LOADING, true);
      const users = await getAllUsers();
      commit(Mutations.SET_USERS, users.data);
      commit(Mutations.SET_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
      commit(Mutations.SET_LOADING, false);
    }
  },
  async [Actions.CHANGE_PASSWORD](
    { commit, state, dispatch },
    { newPassword }
  ) {
    try {
      commit(Mutations.SET_LOADING, true);
      const user = getAuth().currentUser;
      await updatePassword(user, newPassword);
      await updateDoc(doc(db, firestoreCollection.users, user.email), {
        isFirstLogin: false
      });

      const updatedUser = { ...state.user };
      updatedUser.isFirstLogin = false;
      await dispatch(Actions.LOG_EVENT, {
        user: user.email,
        action: LOG_ACTIONS.CHANGE_PASSWORD,
        module: MODULE.AUTH,
        target: JSON.stringify({ name: user.email, id: user.uid }),
        datePerformed: serverTimestamp()
      });
      commit(Mutations.SET_AUTH, updatedUser);
      commit(Mutations.SET_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
      commit(Mutations.SET_LOADING, false);
    }
  },
  async [Actions.RESET_PASSWORD_CODE]({ commit }, { newPassword, oobCode }) {
    try {
      commit(Mutations.SET_ERROR, null);
      commit(Mutations.SET_LOADING, true);
      await confirmPasswordReset(getAuth(), oobCode, newPassword);
      commit(Mutations.SET_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
      commit(Mutations.SET_LOADING, false);
    }
  },
  async [Actions.REQUEST_RESET_PASSWORD]({ commit }, { email, loggedIn }) {
    let res = null;
    try {
      commit(Mutations.SET_LOADING, true);
      /**
       * Logging is made on server side because
       * there is no session when calling this action
       */
      res = await sendEmailVerificationLink(email, loggedIn);
      commit(Mutations.SET_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
      commit(Mutations.SET_LOADING, false);
    }
    return res;
  },
  async [Actions.REAUTHENTICATE]({ commit }, { email, password }) {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      await reauthenticateWithCredential(auth.currentUser, credential);
      return { success: true, message: "Reauthenticated" };
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
      return { success: false, message: "Wrong Credentials" };
    }
  },
  // set user theme data to fire store
  async [Actions.SET_THEME]({ commit }, { colorPalette, darkMode, user }) {
    try {
      await updateDoc(doc(db, firestoreCollection.users, user.email), {
        theme: { colorPalette, darkMode }
      });
      commit(Mutations.SET_AUTH, {
        ...user,
        theme: {
          colorPalette: colorPalette,
          darkMode: darkMode
        }
      });
      return { success: true, message: "Theme Updated.", error: null };
    } catch (error) {
      commit(Mutations.SET_ERROR, error);
      return { success: false, message: "Error Updating Theme.", error };
    }
  }
};
