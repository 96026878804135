export const parseUserAccountsToCsvFormatFull = (users, isDealer) => {
  const headings = [
    "EMAIL",
    `${isDealer ? "DEALER NAME" : "NAME"}`,
    "ROLE",
    `${isDealer ? "CUSTOMER ID" : "JOB DESCRIPTION"}`,
    "PASSWORD RENEWED",
    "LAST SIGN IN",
    "MONGO ID",
    "UID"
  ];
  return [
    headings,
    ...users.map((user) => {
      return [
        user?.email ?? "",
        user?.name?.toUpperCase() ?? "",
        user?.role ?? "",
        `${isDealer ? user?.priorityId ?? "" : user?.jobDesc ?? ""}`,
        !user?.isFirstLogin ? "YES" : "NO",
        user?.lastSignInTime ?? "",
        user?.mongoId ?? "",
        user?.uid ?? ""
      ];
    })
  ];
};
